import React, { Component } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { getUserFromJWT } from "../utils/jwt.decode";
import Col from "react-bootstrap/Col";
import login from "../api/auth";
import Cookies from "js-cookie";
import "../css/main.css"

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      rememberPass: false,
      loginError: "",
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();
    try {
      const result = await login(this.state.userName, this.state.password);
      console.log(result);
      if (result.status === 200) {
        Cookies.set("jwt", result.data.token);
        const user = getUserFromJWT();
        if (user) {
          this.setState({
            currentUser: {
              userName: user.userName,
              userId: user.userId,
              isSuperAdmin: user.isSuperAdmin,
            },
            isLoggedIn: true,
          });
          Cookies.set("currentUser", user.userName);
          Cookies.set("isSuperAdmin", user.isSuperAdmin);
        }
        this.props.afterLogin();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.setState({ loginError: "Погрешно корисничко име или лозинка" });
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  render() {
    return (
      <div className="myLogIn">
        <Row className="box-section login">
          <Col className="text-group" sm={12} md={12}>
            <Form>
              <br />

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="black">Корисничко име</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Вашето корисничко име"
                  name="userName"
                  value={this.state.userName}
                  onChange={this.handleChange}
                  required={true}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="black">Лозинка</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Вашата лозинка"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  required={true}
                />
                {this.state.errors.password ? (
                  <p className="text-danger">{this.state.errors.password}</p>
                ) : null}
              </Form.Group>
              <Form.Group controlId="formBasicChecbox">
                {this.state.loginError !== "" ? (
                  <Form.Label className="error">
                    {this.state.loginError}
                  </Form.Label>
                ) : null}
              </Form.Group>
              <br />
              <Button
                variant="primary btn-block button-standard"
                onClick={this.handleSubmit}
                type="submit"
                value="Submit"
              >
                Влез
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
