import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap'
import messagesApi from '../../api/messages.api'
import { toast } from 'react-toastify'
import UsersApi from '../../api/users.api'
import { MessageTypes } from '../../common/enums'
import UploadsAPI from '../../api/uploads.api'

export default class imageOnly extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageUrl: '',
      imageUrlErr: null,
      selectedFile: null,
      selectedimage: null,
      imageFileErr: null,
      imageFileName: '',
      numbersFileName: '',
    }
    this.SendBulk = this.SendBulk.bind(this)
    this.onChangeimageHandler = this.onChangeimageHandler.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  async SendBulk() {
    this.setState({ messageText: '' })

    if (this.validateForm()) {
      await messagesApi.sendBulk({
        fileName: this.state.numbersFileName,
        tagLbl: this.props.tagLabel,
        imageUrl: `${process.env.REACT_APP_IMAGES_URL}/${this.state.imageFileName}`,
        messageType: MessageTypes.ImageOnly,
      })
      this.props.resetState()
      this.setState({
        imageUrl: '',
        imageUrlErr: null,
        selectedFile: null,
        selectedimage: null,
        imageFileErr: null,
        imageFileName: '',
        numbersFileName: '',
      })
      toast.success('Пораката беше испратена')
    }
  }

  async onChangeimageHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      )
      if (target.toLowerCase() == 'png' || target.toLowerCase() == 'jpg') {
        const data = new FormData()
        data.append('file', event.target.files[0])
        var response = await UploadsAPI.uploadImage(data)
        this.setState({ imageFileName: response.data.filename, fileError: '' })
      } else {
        this.setState({
          imageFileErr:
            'Неправилен формат на фајл. Форматот мора да биде .png или .jpg',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  async onChangeHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      )
      if (target.toLowerCase() == 'xlsx') {
        const data = new FormData()
        data.append('file', event.target.files[0])
        var response = await UsersApi.uploadNumbers(data)
        this.setState({
          numbersFileName: response.data.filename,
          fileError: '',
        })
      } else {
        this.setState({
          fileError: 'Неправилен формат на фајл. Форматот мора да биде .xlsx',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  validateForm() {
    let formValid = true
    console.log(this.props)

    if (this.props.imageUrl == '') {
      if (this.state.imageFileName == '') {
        this.setState({
          imageUrlErr: 'Ве молиме внесете URL-адреса или прикачете слика',
        })
        formValid = false
      }
    }
    if (this.props.tagLabel == '') {
      this.setState({ tagLabelErr: 'Ве молиме внесете ознака за кампања' })
      formValid = false
    }
    if (this.state.numbersFileName == '') {
      this.setState({ fileError: 'Ве молиме одберете датотека со броеви' })
      formValid = false
    }
    return formValid
  }
  render() {
    return (
      <Col>
        <Form>
          <Form.Group as={Row}>
            <Col sm="10">
              {/* <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">URL-адреса Слика</label>
                <FormControl
                  type="text"
                  placeholder="Внесете URL-адреса на сликата"
                  onChange={this.props.handleChange}
                  name="imageUrl"
                  value={this.props.imageUrl}
                ></FormControl>
                <p className="error">{this.state.imageUrlErr}</p>
              </Col> */}
              <Col>
                <label className="col-md-12 pl-0 bold-text">
                  Одберeте слика
                </label>
                <input
                  type="file"
                  name="imageFileName"
                  onChange={this.onChangeimageHandler}
                  value={this.state.selectedFile}
                />
                <p className="error">{this.state.imageUrlErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Ознака на кампања</label>
                <FormControl
                  type="text"
                  placeholder="Внесете ознака на кампањата"
                  onChange={this.props.handleChange}
                  name="tagLabel"
                  value={this.props.tagLabel}
                ></FormControl>
                <p className="error">{this.state.tagLabelErr}</p>
              </Col>
              <Col>
                <label className="col-md-12 pl-0 bold-text">
                  Одберeте датотека со броеви
                </label>
                <input
                  type="file"
                  name="numbersFileName"
                  onChange={this.onChangeHandler}
                  value={this.state.selectedFile}
                />
                <p className="error">{this.state.fileError}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <Button
                  variant="outline-warning mb-3 mt-3"
                  onClick={this.SendBulk}
                  sm="3"
                  size="sm"
                  className="margin-top-10px button-standard"
                >
                  Испрати
                </Button>
              </Col>
            </Col>
          </Form.Group>
          <Form.Group as={Row}></Form.Group>
        </Form>
      </Col>
    )
  }
}
