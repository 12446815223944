import Axios from "axios";
import Cookies from "js-cookie";
class Messages {


    async sendBulk(req) {
      try {
        const token = Cookies.get("jwt");
        const configAPI = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
    
        const url = `${process.env.REACT_APP_API_BASE_URL}/bulk/viber`;
    
        const response = await Axios.post(url, req, configAPI);
        return response;
      } catch (error) {
        throw error;
      }
    }
    

}

export default new Messages();
