import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import LoginPage from "./components/Login";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Logo from "./images/zanmitrev-footer-logo-removebg-preview.png";
import Cookies from "js-cookie";
import { getUserFromJWT } from "./utils/jwt.decode";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/navbar.css";
import "./css/main.css";
import ViberBulk from "./components/ViberBulk";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isLoggedIn: false,
    };
    this.reRender = this.reRender.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  componentDidMount() {
    const user = getUserFromJWT();
    if (user) {
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          isSuperAdmin: user.isSuperAdmin,
          role: user.role,
        },
        isLoggedIn: true,
      });
      Cookies.set("currentUser", user.userName);
      Cookies.set("isSuperAdmin", user.isSuperAdmin);
      Cookies.set("role", user.role);
    }
  }

  handleLogOut() {
    Cookies.remove("jwt");
    Cookies.remove("currentUser");
    Cookies.remove("isSuperAdmin");
    Cookies.remove("role");
    this.setState({ currentUser: null, isLoggedIn: false });
    window.location.replace("/");
  }

  reRender() {
    this.forceUpdate();
    const user = getUserFromJWT();
    if (user)
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          isSuperAdmin: user.isSuperAdmin,
          role: user.role,
        },
        isLoggedIn: true,
      });
  }

  render() {
    if (this.state.isLoggedIn) {
      return (
        <Router>
          <ToastContainer />
          <Navbar
            sticky="top"
            expand="lg"
            variant="light"
            className="main-navigation myNavbar"
          >
            <Navbar.Brand>
              <Link to="/" className="navbar-brand">
                <img src={Logo} alt="" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="myNav">
              <Nav className="mr-auto">
                <Link to="/" className="nav-link-item">
                  
                  Вибер Булк
                </Link>
                </Nav>
                <Nav className="mr-auto">
                <NavDropdown
                  title={Cookies.get("currentUser")}
                  id="basic-nav-dropdown"
                  className="nav-link-item myNavDropdown"
                >
                  <NavDropdown.Item
                    onClick={this.handleLogOut}
                    className="nav-ling-item"
                  >
                    Одјави се
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Routes>
            <Route path="/" element={<ViberBulk />} />

            {/* Add more routes as needed */}
          </Routes>
        </Router>
      );
    } else {
      return <LoginPage afterLogin={this.reRender} />;
    }
  }
}

export default App;
