import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

export const getUserFromJWT = () => {
  const jwt = Cookies.get('jwt');
  if (jwt) {
    return jwtDecode(jwt);
  }
  return null;
};
